let cloudinaryMappingFolder
const getCloudinaryMappingFolder = () => cloudinaryMappingFolder ||=
  document.querySelector('meta[name="cloudinary-mapping-folder"]')
  .getAttribute('content')

let cloudinaryOrigin
const getCloudinaryOrigin = () => cloudinaryOrigin ||=
  document.querySelector('meta[name="cloudinary-origin"]')
  .getAttribute('content')

const smartEscape = (string, unsafe = /([^a-zA-Z0-9_.\-\/:]+)/g) =>
  string.replace(unsafe, match =>
    match.split('').map(c =>
      '%' + c.charCodeAt(0).toString(16).toUpperCase()
    ).join('')
  )

const mapKey = key => ({ border: 'bo' })[key] || key[0]

const cloudinaryUrl = (source, options={}, {
  origin=getCloudinaryOrigin(),
  mappingFolder=getCloudinaryMappingFolder(),
}={}) => {
  if (
    source.slice(-4).toLowerCase() == '.svg' &&
    (!options.fetchFormat || options.fetchFormat.toLowerCase() == 'svg')
  ) {
    delete options.width
    delete options.height
    delete options.crop
  }

  const params = Object.keys(options).map(key =>
    smartEscape([ mapKey(key), options[key] ].join('_'))
  ).join()

  return [
    origin,
    params,
    mappingFolder,
    smartEscape(source.replace(/^media\//, '')),
  ].filter(Boolean).join('/')
}

export default cloudinaryUrl
