import MutationSummary  from 'mutation-summary'
import { h, render }    from 'preact'

import App from './App'

const PROD_ATTR = 'data-round-up-via-pledgeling'
const LOAD_ATTR = 'data-stay-back-this-round-up-is-loaded'

const script = document.currentScript
const url = new URL(script.src, location)
const origin = url.origin

const infoUrl = path =>
  `${origin}/shop/info/${path}?shop=${encodeURIComponent(Shopify.shop)}`

const initDonation = element => {
  if (element.getAttribute(LOAD_ATTR)) return
  element.setAttribute(LOAD_ATTR, '1')

  const handle = element.getAttribute(PROD_ATTR)

  const success = product =>
    product.currency.iso_code == Shopify.currency.active
    ? fetchCart(cart => render(h(App, { ...element.dataset, product, cart }), element))
    : console.error('Active currency does not match shop default currency. Round-up disabled.')

  const url = infoUrl(`donation_button/${handle}`)
  fetch(url, { headers: { Accept: 'application/json' } })
  .then(response => {
    if (response.ok)
      response.json().then(success)
    else {
      console.error('Fetching product returned an error', handle, response)
    }
  }).catch(error =>
    console.error('Fetching product failed due to network error', handle, error)
  )
}

const initDonations = () => {
  const selector = `[${PROD_ATTR}]`
  document.querySelectorAll(selector).forEach(initDonation)
  new MutationSummary({
    callback (summaries) {
      summaries[0].added.forEach(initDonation)
    },
    queries: [{ element: selector }]
  })
}

const fetchCart = success =>
  fetch('/cart', { headers: { Accept: 'application/json' } })
  .then(response => response.json()).then(success)
  .catch(error =>
    console.error("Fething cart failed. Round-up buttons won't render", error)
  )

export const init = () => {
  if (!origin)
    return console.error('Extracting script origin failed')

  if (typeof Shopify != 'object')
    return console.error('Shopify object not found')

  initDonations()
}
