import { h } from 'preact'
import { useState, useEffect, useRef, useMemo } from 'preact/hooks'
import MutationSummary from 'mutation-summary'
import ResizeSensor from 'css-element-queries/src/ResizeSensor'
import deepmerge from 'deepmerge'
import Big from 'big.js'

import I18n from 'src/components/I18n'
import Logo from './Logo'

import { backgroundColor as ogBackgroundColor, styles as ogStyles } from './styles'

import ogFormatAmount from '../../utils/formatAmount'
import cloudinaryUrl from 'src/utils/cloudinaryUrl'
import orgLocation from 'src/utils/orgLocation'
import { colorToObj, brightness, colorContrast } from 'src/utils/Color'

// Tell transpiler to transform JSX into h() calls:
/** @jsx h */

const hideIconOnLegacyThemes = () => {
  const className = 'hide-this-icon-on-legacy-themes'
  const firstStyleSheet = document.querySelector('style, link[rel="stylesheet"]')
  if (!firstStyleSheet || firstStyleSheet.id == className) return
  const style = document.createElement('style')
  style.id = className
  style.textContent = `.${className} { height: 0 }`
  firstStyleSheet.parentNode.insertBefore(style, firstStyleSheet)
}

const usdFormat = '${{amount}}'
const light = '#fff'
const dark = '#333'

const rgba = (c, a) => (o => `rgba(${o.r},${o.g},${o.b},${a})`)(colorToObj(c))

const Donation = ({ product, preview, ajax, children, ...props }) => {
  const btnRuler = useRef()
  const linkRuler = useRef()
  const donationBlockOuter = useRef()
  const donationBlock = useRef()
  const moreInfo = useRef()
  const buttons = useRef()
  const [ width, setWidth ] = useState(null)
  const [ btnStyle, setBtnStyle ] = useState()
  const [ linkStyle, setLinkStyle ] = useState({})
  const [ baseStyle, setBaseStyle ] = useState({})
  const [ blockButtons, setBlockButtons ] = useState(false)
  const [ cart, setCart ] = useState(props.cart)

  const cartItem = cart?.items.find(item => item.product_id == product.product_id)
  const cartItemAmount = cartItem
  ? Big(cartItem.price).div(100).times(cartItem.quantity)
  : Big(0)

  const wholeAmount = Big(product.dollar_button || 1)

  const organizations = product.variants?.map(variant =>
    product.organizations.find(org => org.id == variant.organization_id)
  ) || product.organizations

  const variantInCart = cartItem && product.variants?.find(variant =>
    cartItem.variant_id == variant.dollar_variant_id
  )
  const orgInCart = variantInCart && organizations
  .find(org => org.id == variantInCart.organization_id)

  const getDefaultVariant = () => {
    const { dollar_variant_id, dollar_price } = product
    return { organization_id: '', dollar_variant_id, dollar_price }
  }

  const [ variant, setVariant ] = useState(variantInCart || getDefaultVariant)

  const handleOrgChange = e =>
    setVariant(product.variants.find(v => v.organization_id == e.target.value)
      || getDefaultVariant)

  useEffect(hideIconOnLegacyThemes, [])

  const rem = useMemo(() =>
    parseFloat(getComputedStyle(document.documentElement).fontSize),
  [ document.documentElement.clientWidth ])

  const em = useMemo(() =>
    donationBlockOuter.current
    ? parseFloat(getComputedStyle(donationBlockOuter.current).fontSize) : 16,
  [ donationBlockOuter.current, document.documentElement.clientWidth ])

  const threshold = useMemo(() => 500 * (em / rem), [ width ])

  const fetchCart = () =>
    fetch('/cart', { headers: { Accept: 'application/json' } })
    .then(response => response.json().then(json => {
      if (response.ok) setCart(json)
    }))

  useEffect(() => {
    if (!ajax) return
    const handleCartChange = e => e.detail ? setCart(e.detail) : fetchCart()
    addEventListener('ShopifyCartChanged', handleCartChange)
    return () => removeEventListener('ShopifyCartChanged', handleCartChange)
  }, [])

  useEffect(() => {
    if (product.scale == 1 || !product.scale) return

    if (!btnRuler.current)
      btnRuler.current = (() => {
        const btn = document.createElement('button')
        btn.className = 'btn button'
        btn.style.position = 'absolute'
        btn.style.left = '-1000000px'
        donationBlockOuter.current.parentNode.appendChild(btn)
        return btn
      })()

    setBtnStyle(getComputedStyle(btnRuler.current))
  }, [ product.scale, document.documentElement.clientWidth ])

  useEffect(() => {
    if (!linkRuler.current)
      linkRuler.current = (() => {
        const link = document.createElement('a')
        link.href = `/?timestamp=${Date.now()}`
        link.style.position = 'absolute'
        link.style.left = '-1000000px'
        donationBlockOuter.current.parentNode.appendChild(link)
        return link
      })()

    setLinkStyle(getComputedStyle(linkRuler.current))
    setBaseStyle(getComputedStyle(donationBlockOuter.current.parentNode))
  }, [ document.documentElement.clientWidth ])

  useEffect(() => {
    const handleResize = () => setWidth(donationBlockOuter.current.offsetWidth)
    const resizeSensor = new ResizeSensor(donationBlockOuter.current, handleResize)
    handleResize()
    return () => resizeSensor.detatch(donationBlockOuter.current, handleResize)
  }, [])

  useEffect(() => {
    if (!buttons.current) return

    setBlockButtons(
      width < threshold &&
      Array.prototype.reduce.call(buttons.current.children, (widths, col) => {
        const display = col.style.display
        col.style.display = 'inline-block'
        const colWidth = col.offsetWidth
        col.style.display = display
        return widths + colWidth
      }, 0) > buttons.current.offsetWidth
    )
  }, [ width, threshold ])

  const [ customersChoiceAvailable, setCustomersChoiceAvailable ] = useState(false)

  useEffect(() => {
    const selector = '[name="attributes[Nonprofit]"]'
    setCustomersChoiceAvailable(!!document.querySelector(selector))
    const observer = new MutationSummary({
      callback (summaries) {
        setCustomersChoiceAvailable(!!summaries[0].added.length)
      },
      queries: [{ element: selector }]
    })
    return () => observer.disconnect()
  }, [])

  const formatAmount = amount =>
    ogFormatAmount(amount, product.money_format || usdFormat)

  const addDonation = quantity => {
    if (preview) return

    const url = `/cart/${cartItem ? 'change' : 'add'}`

    if (ajax) {
      const params = { quantity, id: variant.dollar_variant_id }
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      }).then(response => response.json().then(detail => {
        if (response.ok) {
          dispatchEvent(new CustomEvent('PledgeDonationChanged', { detail }))
          fetchCart()
        }
      }))
    } else {
      const container = document.createElement('div')
      container.innerHTML = `
        <form method="post" action="${url}">
          <input type="hidden" name="quantity" value="${quantity}">
          <input type="hidden" name="id" value="${variant.dollar_variant_id}">
        </form>`
      document.body.appendChild(container)
      container.children[0].submit()
    }
  }

  const cloudinaryImage = (source, size) =>
    cloudinaryUrl(source,
      { width: size, height: size, crop: 'pad' },
      product.cloudinary_settings)

  const derive = x => product.derive.indexOf(x) > -1
  const scaleProps = (...props) => props.reduce((css, prop) => {
    css[prop] = parseFloat(btnStyle[prop]) * product.scale + 'px'
    return css
  }, {})

  const backgroundColor = /^#([0-9a-f]{3}){1,2}$/i.test(product.bg_color)
  ? product.bg_color : ogBackgroundColor
  const prefColor = baseStyle.color || dark
  const altColor = brightness(prefColor) > 50 ? dark : light
  const color = colorContrast(backgroundColor, prefColor, altColor)
  const prefLinkColor = linkStyle.color || prefColor
  const linkColor = colorContrast(backgroundColor, prefLinkColor, altColor)
  const deriveColor = color == altColor
  const deriveLink = derive('link') || linkColor == altColor
  const fontSize = product.scale + 'em'

  const overrides = {
    donationBlockOuter: { fontSize },
    donationBlock: { backgroundColor },
    mainCopy: deriveColor ? { color } : {},
    orgChoiceLabel: deriveColor ? { color } : {},
    poweredBy: deriveColor ? { color } : {},
    link: deriveLink ? { color } : {},
    btnColumn: blockButtons ? { display: 'block' } : {},
    btn: {
      ...(blockButtons ? { display: 'block', width: '100%' } : {}),
      ...(btnStyle
        ? scaleProps('fontSize', 'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft')
        : {}),
      ...(derive('button') ? {
        backgroundColor: 'transparent',
        border: `1px solid ${deriveColor ? color : 'currentColor'}`,
        color: deriveColor ? color : 'inherit',
      } : {}),
    },
    moreInfo: {
      backgroundColor,
      ...(deriveColor ? { color } : {}),
    },
    orgLocation: deriveColor ? { color: rgba(color, 0.9) } : {},
    processingInfo: deriveColor ? { color: rgba(color, 0.9) } : {},
  }

  const styles = width >= threshold
  ? deepmerge.all([ogStyles.base, ogStyles.large, overrides])
  : deepmerge.all([ogStyles.base, ogStyles.small, overrides])

  const i18n = I18n(product.i18n)

  const copy = cartItem
  ? i18n.t('thank_you_for_donating_in_support_of',
    { amount: formatAmount(cartItemAmount) })
  : i18n.t('add_a_donation_in_support_of')

  const [ beforeOrgName, afterOrgName ] = copy.trim().split('%{link}')

  const orgName =
  <a href="#more-info" role="button" style={styles.link} onClick={e => {
    e.preventDefault()
    moreInfo.current.showModal()
  }}>
    {orgInCart?.name || (
      organizations.length == 1
      ? organizations[0].name
      : i18n.t('these_n_amazing_causes', { n: organizations.length })
    )}
  </a>

  const btnProps = {
    type: 'button',
    className: 'btn button gng-ru--btn',
    style: styles.btn,
    disabled: product.disable_split && !variant.organization_id,
  }

  return (
    <div
      className="gng-ru--donationBlockOuter"
      style={styles.donationBlockOuter}
      ref={donationBlockOuter}
    >
      {children}
      <div
        className="gng-ru--donationBlock"
        style={styles.donationBlock}
        ref={donationBlock}
      >
        <div
          className="gng-ru--donationBlockInner"
          style={styles.donationBlockInner}
        >
          <div className="gng-ru--mainCopy" style={styles.mainCopy}>
            {beforeOrgName}{orgName}{afterOrgName}
            {
              Number(product.match) ?
              <span>
                {'. '}
                {i18n.t('we_will_match_your_donation_up_to',
                  { amount: formatAmount(product.match) })}
              </span>
              : null
            }
          </div>

          {
            !cartItem && product.variants &&
            <div className="gng-ru--orgChoice" style={styles.orgChoice}>
              <label
                htmlFor="plgNonprofit"
                className="gng-ru--orgChoiceLabel"
                style={styles.orgChoiceLabel}
              >
                {i18n.t('choose_nonprofit_label')}
              </label>
              <div className="select">
                <select
                  id="plgNonprofit"
                  onChange={handleOrgChange}
                  className="select__select"
                  style={{ fontSize: 'inherit' }}
                >
                  <option value="">
                    {i18n.t(
                      product.disable_split
                      ? 'choose_nonprofit_single'
                      : 'choose_nonprofit_split'
                    )}
                  </option>
                  <optgroup label="Nonprofits">
                    {product.variants.map(variant => {
                      const organization = organizations
                      .find(org => org.id == variant.organization_id)
                      return organization &&
                        <option
                          key={organization.id}
                          value={organization.id}
                          disabled={!variant.dollar_variant_id || !variant.dollar_price}
                        >
                          {organization.name}
                        </option>
                    }).filter(Boolean)}
                  </optgroup>
                </select>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  className="icon-caret hide-this-icon-on-legacy-themes"
                  viewBox="0 0 10 6"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          }

          {
            cartItem ? null :
            <div className="gng-ru--buttonsColumn" style={styles.buttonsColumn}>
              <div className="gng-ru--buttons" style={styles.buttons} ref={buttons}>
                <div className="gng-ru--btnColumn" style={styles.btnColumn}>
                  <button
                    {...btnProps}
                    onClick={() => addDonation(wholeAmount)}
                    disabled={variant.dollar_price != '1.00'}
                  >
                    {i18n.t('donate', { amount: formatAmount(wholeAmount) })}
                  </button>
                </div>
              </div>
            </div>
          }

          <div className="gng-ru--poweredBy" style={styles.poweredBy}>
            powered by
            <a href="https://www.pledge.to/" target="_blank"
              className="gng-ru--poweredByLink" style={styles.poweredByLink}>
              <Logo className="gng-ru--poweredByLogo" style={styles.poweredByLogo} />
            </a>
          </div>
        </div>

        <dialog className="gng-ru--moreInfo" style={styles.moreInfo} ref={moreInfo}>
          <p className="gng-ru--moreInfoClose" style={styles.moreInfoClose}>
            <a href="#close" role="button" style={styles.link} onClick={e => {
              e.preventDefault()
              moreInfo.current.close()
            }}>&times; Close</a>
          </p>

          {
            organizations.length > 1
            ? product.variants
            ? <p>
                Your donation can be split evenly among the below organizations or
                you can choose one of the below organizations to receive the full
                donation:
              </p>
            : customersChoiceAvailable
            ? <p>
                Your donation can be split evenly among the below organizations or
                you can choose one of the below organizations to receive the full
                donation when you complete your order:
              </p>
            : <p>Your donation will be split evenly among:</p>
            : null
          }

          {organizations.map(organization =>
            <div key={organization.id} className="gng-ru--org" style={styles.org}>
              <div className="gng-ru--orgImgColumn" style={styles.orgImgColumn}>
                <img
                  className="gng-ru--orgImg"
                  style={styles.orgImg}
                  src={cloudinaryImage(organization.logo_img, 80)}
                />
              </div>

              <div className="gng-ru--orgInfo" style={styles.orgInfo}>
                <a
                  className="gng-ru--link"
                  style={styles.link}
                  href={`https://www.pledge.to/organizations/${organization.ein}/${organization.slug}`}
                  target="_blank"
                ><b>{organization.name}</b></a>
                <p className="gng-ru--orgLocation" style={styles.orgLocation}>
                  {orgLocation(organization)}
                </p>
              </div>
            </div>
          )}

          <p className="gng-ru--processingInfo" style={styles.processingInfo}>
            Learn about <a style={styles.link}
              href={product.support_url}
              target="_blank">donation processing costs</a>.
          </p>
        </dialog>
      </div>
    </div>
  )
}

export default Donation
